<template>
  <div id="Setting-Extract-Currency-Address">
    <a-form-model
      ref="formModel"
      :model="formModel.item"
      :rules="formModel.rules"
      v-bind="{
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
      }"
    >
      <a-form-model-item
        has-feedback
        :label="`设置 ${formModel.item.currency} 提币币种`"
        prop="currency"
        v-if="true">
        <a-select v-model="formModel.item.currency" @select="getCurrencyInfo">
          <!-- <a-select-option value="aleo">ALEO</a-select-option> -->
          <a-select-option value="smh">SMH</a-select-option>
          <!-- <a-select-option value="usdt">USDT</a-select-option> -->
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        has-feedback
        :label="`设置 ${formModel.item.currency} 提币地址`"
        prop="address"
      >
        <a-input v-model="formModel.item.address" placeholder="请设置地址" />
      </a-form-model-item>

      <password-enter
        :pwd.sync="formModel.pay_pwd_plainCode"
        :show.sync="formModel.password_enter_show"
        @ok="form_password_enter"
      />
      <a-form-model-item>
        <a-button
          id="form-submit-btn"
          ref="form-submit-btn"
          @click="form_submit()"
          :disabled="submitButton"
        >
          确认修改提币地址
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import "@/components/js/_form_common";
import passwordEnter from "@/components/Password-Enter";

export default {
  props: {},
  components: {
    passwordEnter,
  },
  data() {
    return {
      currencyInfo: {},
      formModel: {
        item: {
          currency: "SMH", // 提币币种
          address: "", // 提币地址
          pay_pwd: "",
        },
        pay_pwd_plainCode: "",
        rules: {
          currency: [
            {
              required: true,
              whitespace: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                if (!value) callback(new Error("必须选择一个币种"));
                else callback();
              },
            },
          ],
          address: [
            {
              required: true,
              whitespace: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                if (!value) callback(new Error("必须填写提币地址"));
                else callback();
              },
            },
          ],
        },
        password_enter_show: false,
      },
      submitButton: false,
    };
  },
  methods: {
    async getCurrencyInfo() {
      this.currencyInfo = await this.$Api.get().getCurrencyInfo({
        currency: this.formModel.item.currency,
      });

      this.formModel.item.address = this.currencyInfo.blockchain_address;
    },
    form_submit() {
      this.$refs["formModel"].validate((valid) => {
        if (valid) this.formModel.password_enter_show = true;
      });
    },
    form_password_enter() {
      this.$refs["formModel"].validate((valid) => {
        if (valid) this.form_password_confirm();
      });
    },
    form_password_confirm() {
      this.formModel.item.pay_pwd = this.$axios.Base64.encode(
        this.formModel.pay_pwd_plainCode
      );

      this.$Api.post().changeWalletAddress(this.formModel.item);
    },
  },
  created() {
    this.getCurrencyInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Setting-Extract-Currency-Address {
  padding: 2em 0;

  .ant-form {
    //: 0.3rem;
    color: white !important;

    * {
      //: inherit;
    }

    > .ant-form-item:last-child {
      .ant-btn {
        width: 100%;
        color: white;
        border-color: #828ac3;
        background-color: #3d447b;
        height: 3em;
        line-height: 3em;
        padding: 0 0.5em;
      }
    }
  }
}
</style>
